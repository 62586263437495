import {
    getModifierGroupId,
    getModifiersKey,
    mapModifierGroups,
    mapModifiersToGroups,
    prepareModifierGroups, sortModifierGroups
} from '../helpers';
import {getAbsolutePriceString, getRelativePriceString, parsePrice} from "../helpers/price-formatter/index";

/**
 * Формат product: {id, price}
 * Формат groups: идентичен АПИ (СУ: MenuProductModifierGroup::toApiData())
 * Формат modifiers: {id, name, price, group_id, is_default_choice}
 * Формат selected: {id, ...<любые_данные_которые_нужно_передать_назад>}
 *
 * Формат результата: getResult()
 */
export default {
    name: 'ProductModifierGroupsMixin',
    props: [
        // Исходные данные
        'product', 'groups', 'modifiers', 'selected',
        // Сервисные значения
        'disableAutoSelect',
        // Можно ли отменять выбор модификатора даже с is_choice_required
        'allowChoiceRequiredRemove',
    ],
    computed: {
        modifiersAvailable() {
            return this.modifiers.filter(m => m.max_quantity !== 0 && m.full_stop !== true);
        },
        allModifierGroups() {
            return sortModifierGroups(
                prepareModifierGroups(this.groups)
            );
        },
        modifierGroups() {
            return this.allModifierGroups
                .filter(g => {
                    return g.condition_product_ids === undefined
                        || g.condition_product_ids.some(id => id == this.calcProduct.id)
                        || this.selectedModifiers.some(sm => g.condition_product_ids.some(id => id == sm.id))
                })
        },
        modifierGroupsMap() {
            return mapModifierGroups(this.allModifierGroups);
        },
        modifierGroupsVisibleMap() {
            const output = {};
            for(const group of this.modifierGroups) {
                output[group.id] = group;
            }
            return output;
        },
        isShowGroupTitle() {
            return this.modifierGroups.length > 1;
        },
        groupedModifiers() {
            return mapModifiersToGroups(this.modifiersAvailable);
        },
        endPrice() {
            let basePrice = parsePrice(this.calcProduct.price);
            let modsPrice = 0;

            for(const modifier of this.selectedModifiers) {
                if(this.baseProduct && modifier.id == this.baseProduct.id) {
                    continue;
                }

                const modPrice = parsePrice(modifier.price);
                const modQuantity = modifier.quantity || 1;

                modsPrice += modPrice * modQuantity;
            }

            return basePrice + modsPrice;
        },
        calcProduct() {
            return this.baseProduct || this.product;
        },
        isValidChoiceRequiredGroups() {
            // Отфильтруем в мапу группы is_choice_required = true
            const isChoiceRequiredGroupsMap = Object.values(this.modifierGroupsVisibleMap)
                .reduce((carry, item) => {
                    if(item.is_choice_required) {
                        carry[item.id] = item;
                    }
                    return carry;
                }, {});

            // Удалим из мапы те группы, которые есть в selectedModifiers
            for(const mod of this.selectedModifiers) {
                if(isChoiceRequiredGroupsMap[mod.group_id] !== undefined) {
                    delete isChoiceRequiredGroupsMap[mod.group_id];
                }
            }

            // Во всех is_choice_required-группах должны быть выбраны продукты
            return Object.keys(isChoiceRequiredGroupsMap).length === 0;
        },
        isValidChoiceModifiers() {
            const modifierGroups = this.product.modifier_groups;
            // если у товара есть группы модификаторов
            if (modifierGroups && modifierGroups.length > 0 && this.selectedModifiers && this.selectedModifiers.length > 0) {
                let hasError = false;
                // находим модификатор, который является фильтрующим по группам
                const filterModifier = this.selectedModifiers.find(item => item.group_id === 0);
                // фильтруем группы модификаторов по фильтрующему модификатору
                const modifierGroupsFiltered = filterModifier ? modifierGroups.filter(item => {
                    if (item.condition_product_ids && item.condition_product_ids.length > 0) {
                        return item.condition_product_ids.find(id => id === filterModifier.id);
                    }
                    else {
                        return true;
                    }
                }) : modifierGroups;
                // пробегаемся по группам и смотрим на соответствие нашим условиям
                for (const modifierGroup of modifierGroupsFiltered) {
                    // если в данной группе разрешено использование нулевого значения и нет выбранных модификаторов из данной группы
                    if (modifierGroup.empty_value && this.selectedModifiers && !this.selectedModifiers.find(item => item.group_id === modifierGroup.id)) {
                        continue;
                    }
                    // находим
                    const quantityModifierByGroup = this.selectedModifiers.reduce((acc, item) => {
                        if (item.group_id === modifierGroup.id) {
                            return acc + item.quantity;
                        }
                        return acc;
                    }, 0) || 0;
                    const maxModifiersCountFromDB = modifierGroup.max_count;
                    const minModifiersCountFromDB = modifierGroup.min_count;
                    // если модификаторы обязательны, то работаем с мин/макс
                    if (modifierGroup.is_choice_required && (minModifiersCountFromDB || maxModifiersCountFromDB) && modifierGroup.is_multiple_choice) {
                        if (minModifiersCountFromDB && quantityModifierByGroup < minModifiersCountFromDB) {
                            hasError = true;
                            break;
                        }

                        if (maxModifiersCountFromDB && quantityModifierByGroup > maxModifiersCountFromDB) {
                            hasError = true;
                            break;
                        }
                    } else if (!modifierGroup.is_choice_required && maxModifiersCountFromDB && modifierGroup.is_multiple_choice) {
                        if (maxModifiersCountFromDB && quantityModifierByGroup > maxModifiersCountFromDB) {
                            hasError = true;
                            break;
                        }
                    }
                }

                if (hasError) {
                    return false;
                }
            }
            return true;
        },
        isValidEverything() {
            return this.isValidChoiceRequiredGroups
                || false; // Почему false? Заглушка для доп.условий в будущем
        },
        isValidModifiers() {
            const output = [];

            // Отфильтруем в мапу группы is_choice_required = true
            const isChoiceRequiredGroupsMap = Object.values(this.modifierGroupsVisibleMap)
                .reduce((carry, item) => {
                    if(item.is_choice_required) {
                        carry[item.id] = item;
                    }
                    return carry;
                }, {});

            // Проверим наличие модификаторов в selectedModifiers
            for(const modGroup of Object.keys(isChoiceRequiredGroupsMap)) {
                const groupObj = {
                    group: isChoiceRequiredGroupsMap[modGroup].id,
                    isValid: true,
                }
                if(!this.selectedModifiers.find(mod => +mod.group_id == modGroup)) {
                    groupObj.isValid = false,
                    groupObj.errorHeader = isChoiceRequiredGroupsMap[modGroup].error_header || "",
                    groupObj.errorText = isChoiceRequiredGroupsMap[modGroup].error_text || ""
                }
                output.push(groupObj)
            }
            return output
        },
    },
    methods: {
        getModifierGroupId(modifier) {
            return getModifierGroupId(modifier);
        },
        removeModifierByIndex(idx) {
            this.selectedModifiers.splice(idx, 1);
        },
        addModifier(idx, modifier, quantity = 1) {
            const data = Object.assign({}, modifier, {quantity});
            if(typeof idx === 'number' && idx >= 0) {
                this.selectedModifiers[idx] = data;
            } else {
                this.selectedModifiers.push(data);
            }
        },
        addGroupFirstModifier(group) {
            // TODO: Deprecated ??
            const modifiers = this.getGroupModifiers(group);
            if(modifiers.length === 0) {
                return;
            }
            this.addModifier(null, modifiers[0]);
        },
        clickRemoveModifiersByGroupId(groupId) {
            this.removeModifiersByGroupId(groupId);
            this.onChanges();
        },
        removeModifiersByGroupId(groupId) {
            this.selectedModifiers = this.selectedModifiers.filter(m => this.getModifierGroupId(m) != groupId);
        },
        isEmptyGroup(group) {
            return this.selectedModifiers.findIndex(m => this.getModifierGroupId(m) == group.id) < 0;
        },
        isActiveModifier(modifier) {
            return this.selectedModifiers.findIndex(m => m.id == modifier.id && this.getModifierGroupId(m) == this.getModifierGroupId(modifier)) >= 0;
        },
        getGroupModifiers(group) {
            return this.groupedModifiers[group.id] || [];
        },
        getGroupSelectedModifiers(group){
            return this.getGroupModifiers(group).filter(item => this.selectedModifiers.find(mod => item.id == mod.id));
        },
        changeModifierQuantityByStep(group, modifier, isDecrease = false) {
            const step = isDecrease ? -1 : 1;

            const selected = this.findSelectedModifier(group, modifier);
            const newQuantity = selected
                ? selected.quantity + step
                : step;

            this.clickModifier(group, modifier, newQuantity);
        },
        increaseModifier(group, modifier) {
            this.changeModifierQuantityByStep(group, modifier, false);
        },
        decreaseModifier(group, modifier) {
            this.changeModifierQuantityByStep(group, modifier, true);
        },
        clickModifier(group, modifier, quantity) {
            this.selectModifier(group, modifier, quantity);
            this.onChanges();
        },
        canAutoSelectInGroup(group) {
            if(this.disableAutoSelect === true) {
                return false;
            }
            return group.is_choice_required && group.visual_type != 3;
        },
        findSelectedModifierIndex(group, modifier) {
            return this.selectedModifiers.findIndex(m => m.id == modifier.id && this.getModifierGroupId(m) == this.getModifierGroupId(modifier));
        },
        findSelectedModifier(group, modifier) {
            const idx = this.findSelectedModifierIndex(group, modifier);
            return idx >= 0 ? this.selectedModifiers[idx] : null;
        },
        selectModifier(group, modifier, quantity) {
            const modifierData = {...modifier, sort: this.getGroupSortOrder(group)};

            let existIndex = this.findSelectedModifierIndex(group, modifierData);
            const isExist = existIndex > -1;
            const isRemove = isExist && typeof quantity !== 'number' || quantity <= 0;

            if(!isExist && isRemove) {
                return;
            }

            // Снятие работает только для дополняющих без обязательного выбора
            if(isRemove) {
                let multipleSelectedModifiers = this.getGroupSelectedModifiers(group).length > 1;

                if(group.is_complement && (multipleSelectedModifiers || this.allowChoiceRequiredRemove || !group.is_choice_required)) {
                    this.removeModifierByIndex(existIndex);
                }
                return;
            }

            // Такого модификатора не было в выбранных
            if (group.is_complement) {
                // дополняет основное блюда - можно выбрать несколько
                if (!group.is_multiple_choice) {
                    this.removeModifiersByGroupId(group.id);
                    existIndex = null;
                    quantity = 1;
                }
                this.addModifier(existIndex, modifierData, quantity);
            } else {
                // заменяет основное блюдо - можно выбрать только один
                this.removeModifiersByGroupId(group.id);
                this.addModifier(existIndex, modifierData);
                this.baseProduct = modifierData;
            }
        },
        selectDefault() {
            // Первоначальный выбор в несколько этапов:
            const selectConditionSteps = [];

            // Сначала выберем по умолчанию
            selectConditionSteps.push(
                (group, modifier, i) => this.canAutoSelectInGroup(group) && (modifier.is_default_choice || modifier.is_default)
            );

            // Затем выберем то, что ранее было выбрано
            if(this.selected && this.selected.length > 0) {
                selectConditionSteps.push(
                    (group, modifier) => this.selected.findIndex(sm => sm.id == modifier.id) >= 0
                );
            }

            for(const selectCondition of selectConditionSteps) {
                for(const group of this.modifierGroups) {
                    const modifiers = this.getGroupModifiers(group);
                    for(const i in modifiers) {
                        const modifier = modifiers[i];

                        if(selectCondition(group, modifier, i)) {
                            this.selectModifier(group, modifier);
                        }
                    }
                }
            }

            this.onChanges({isInit: true});
        },
        getPriceString(group, modifier) {
            const price = parsePrice(modifier.price);

            if(group.is_complement && price > 0) {
                return getRelativePriceString(price);
            } else if(!group.is_complement) {
                return getAbsolutePriceString(price);
            }

            return '';
        },
        onChanges(extended = {}) {
            // Удалим выбранные модификаторы из скрытых групп
            this.removeSelectedFromHiddenGroups();
            // Отправим данные о выбранных модификаторах вне компонента
            this.emitChanges(extended);
        },
        removeSelectedFromHiddenGroups() {
            this.selectedModifiers = this.selectedModifiers.filter(sm => this.modifierGroupsVisibleMap[this.getModifierGroupId(sm)] !== undefined);
        },
        emitChanges(extended = {}) {
            const data = Object.assign({}, extended || {}, this.getResult());

            if(this.modifierGroups.length === 1 && !this.modifierGroups[0].is_complement && !data.isInit) {
                data.isApply = true;
            }

            this.$emit('change', data);
        },
        getResultSelectedModifiers() {
            const output = [];
            for(const mod of this.selectedModifiers) {
                const existData = (this.selected || []).find(s => s.id == mod.id) || {};
                const group = this.modifierGroupsMap[this.getModifierGroupId(mod)];

                output.push(Object.assign({}, existData, mod, {
                    id: +mod.id,
                    price: +mod.price || 0,
                    is_complement: group && group.is_complement,
                    group_id: group ? group.id : null,
                }));
            }
            return output.sort((a, b) => a.is_complement - b.is_complement);
        },
        getResult() {
            let modsKey = '';
            let uniqueKey = `${this.product.id}`;

            if(this.selectedModifiers.length > 0) {
                modsKey = getModifiersKey(this.selectedModifiers);
                uniqueKey = `${uniqueKey}:${modsKey}`;
            }

            return {
                id: +this.product.id,
                modifiers: this.getResultSelectedModifiers(),
                modifiersKey: modsKey,
                uniqueKey: uniqueKey,
                price: +this.endPrice,
                isValid: this.isValidEverything,
            };
        },
        chosenModifierQuantity(groupId) {
            return this.selectedModifiers.reduce((acc, item) => {
                if (item.group_id === groupId) {
                    return acc + item.quantity;
                }
                return acc;
            }, 0);
        },
        maxChosenModifierQuantity(groupId) {
            const findModifier = this.modifierGroups.find(item => item.id === groupId)
            return findModifier ? findModifier.max_count : 99;
        },
        minChosenModifierQuantity(groupId) {
            const findModifier = this.modifierGroups.find(item => item.id === groupId)
            return findModifier ? findModifier.min_count : 0;
        },
        isSuccessChoseModifiers(isChoiceRequired, groupId) {
            if (!isChoiceRequired) {
                return this.chosenModifierQuantity(groupId) > 0;
            }
            return this.chosenModifierQuantity(groupId) <= this.maxChosenModifierQuantity(groupId) && this.chosenModifierQuantity(groupId) >= this.minChosenModifierQuantity(groupId);
        },
        isEmptyChoseModifier(groupId) {
            return !this.chosenModifierQuantity(groupId) || this.chosenModifierQuantity(groupId) === 0;
        },
        isDisabledNoChose(groupId) {
            return this.chosenModifierQuantity(groupId) === this.maxChosenModifierQuantity(groupId);
        },
        getGroupSortOrder(group) {
            if(group.sort_order !== undefined && group.sort_order !== null) {
                return group.sort_order;
            }
            if(group.is_complement == false) {
                return -1;
            }
            return 999;
        },
    },
    mounted() {
        this.selectDefault();
    },
    data() {
        return {
            baseProduct: null,
            selectedModifiers: [],
        };
    },
};
