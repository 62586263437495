<template>
    <div class="modifier-groups__container">
        <div v-for="(group, i) in modifierGroups" class="modifier-groups__group" :key="i">
            <div v-if="isShowGroupTitle" class="modifier-groups__group-title">{{group.title}}</div>
            <div class="modifier-groups__group-items">
                <div>
                    <button
                        v-if="group.is_complement && group.empty_value"
                        class="modifier-groups__group-item modifier-groups__group-item-empty"
                        :class="{'active': isEmptyGroup(group)}"
                        @click="clickRemoveModifiersByGroupId(group.id)"
                    >
                        {{group.empty_value}}
                    </button>
                </div>
                <div v-for="(modifier, i) in getGroupModifiers(group)">
                    <button @click="increaseModifier(group, modifier)">+</button>
                    <button
                        :key="i"
                        class="modifier-groups__group-item"
                        :class="{'active': isActiveModifier(modifier)}"
                        @click="clickModifier(group, modifier)"
                    >
                        {{modifier.name}}&nbsp;{{getPriceString(group, modifier)}}
                    </button>
                    <button @click="decreaseModifier(group, modifier)">-</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Mixin from '../mixin/index';

    export default {
        name: 'ProductModifierGroups',
        mixins: [Mixin],
    };
</script>

<style scoped>
    .modifier-groups__group-items {
        display: flex;
        flex-direction: row;
    }
</style>
