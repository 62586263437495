import {
    CartProductWithModifiers,
    CatalogProductModifier,
    CatalogProductModifierGroup,
    MappedModifierGroups,
    MappedModifiersToGroups,
    MenuProduct,
    ModifierSetProductData, SelectedModifier,
    SimpleProductModifier, SortableModifier
} from "./types";

export function getModifiersKey(modifiers: SimpleProductModifier[]) {
    if(!modifiers) {
        return null;
    }

    const ids: number[] = [];
    for(const modifier of modifiers) {
        ids.push(+modifier.id);
    }

    return ids.sort((a, b) => a - b).join('_');
}

export function findProductIndexWithModifiers(products: CartProductWithModifiers[], productId: number, modifiers: SimpleProductModifier[] = []): number {
    const modKey = getModifiersKey(modifiers);
    return products.findIndex(p => p.id == productId && (p.modifiers_key || '') == (modKey || ''));
}

export function findProductWithModifiers(products: CartProductWithModifiers[], productId: number, modifiers: SimpleProductModifier[] = []): CartProductWithModifiers|undefined {
    const idx = findProductIndexWithModifiers(products, productId, modifiers);
    return idx >= 0 ? products[idx] : undefined;
}

export function generateSetProductExtensionData(modifiers: SelectedModifier[]): {modifiers?: ModifierSetProductData[]} {
    if(!Array.isArray(modifiers) || modifiers.length === 0) {
        return {};
    }

    return {
        modifiers: modifiers.map(m => ({
            id: m.id,
            quantity: m.quantity || 1,
            group_id: m.group_id,
        })),
    };
}

export function getModifierGroupId(modifier: CatalogProductModifier): number {
    return modifier.group_id || 0;
}

export function mapModifiersToGroups(modifiers: CatalogProductModifier[]): MappedModifiersToGroups {
    const output: MappedModifiersToGroups = {};
    for(const modifier of modifiers) {
        const groupId = getModifierGroupId(modifier);
        if(output[groupId] === undefined) {
            output[groupId] = [];
        }
        output[groupId].push(modifier);
    }
    return output;
}

export function prepareModifierGroups(rawGroups: CatalogProductModifierGroup[]|null|undefined): CatalogProductModifierGroup[] {
    const groups = rawGroups ? [...rawGroups] : [];
    if(groups.length === 0) {
        groups.push({
            id: 0,
            is_choice_required: true,
            is_complement: false,
            is_multiple_choice: false,
            title: 'По умолчанию',
            sort_order: 0,
        });
    }
    return groups;
}

function sortGroupsFn(a: CatalogProductModifierGroup, b: CatalogProductModifierGroup): number {
    if(a.is_complement != b.is_complement) {
        // Замещающие идут в первую очередь
        return +a.is_complement - +b.is_complement;
    }
    return a.sort_order - b.sort_order;
}

export function sortModifierGroups(groups: CatalogProductModifierGroup[]): CatalogProductModifierGroup[] {
    return groups.sort(sortGroupsFn);
}

export function mapModifierGroups(groups: CatalogProductModifierGroup[]): MappedModifierGroups {
    const output: MappedModifierGroups = {};
    for(const group of groups) {
        output[group.id] = group;
    }
    return output;
}

export function getProductModifiersMinimumCost(product: MenuProduct, mappedGroups: MappedModifierGroups, mappedModifiers: MappedModifiersToGroups): number {
    let basePrice = product.price || 0;
    let modsCost = 0;

    for(const groupId in mappedGroups) {
        const group: CatalogProductModifierGroup = mappedGroups[groupId];
        if(!group.is_choice_required) {
            continue;
        }

        const mods = mappedModifiers[groupId] || [];
        if(mods.length === 0) {
            continue;
        }

        let minGroupPrice: number|undefined = undefined;
        for(const mod of mods) {
            const price = mod.price || 0;
            if(minGroupPrice === undefined || price < minGroupPrice) {
                minGroupPrice = price;
            }
        }

        minGroupPrice = minGroupPrice || 0;

        if(group.is_complement) {
            modsCost += minGroupPrice;
        } else {
            basePrice = minGroupPrice;
        }
    }

    return basePrice + modsCost;
}

export function getProductModifiersMinimumCostByProduct(product: MenuProduct): number {
    return getProductModifiersMinimumCost(
        product,
        mapModifierGroups(prepareModifierGroups(product.modifier_groups)),
        mapModifiersToGroups(product.available_modifiers || [])
    );
}

export function sortSelectedModifiers(mods: SortableModifier[]): void {
    mods.sort((a, b) => {
        if(typeof a.sort !== 'number') {
            return 1;
        }
        if(typeof b.sort !== 'number') {
            return -1;
        }
        return a.sort - b.sort;
    });
}
